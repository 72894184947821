<template>
  <div>
    <v-card id="loyalty-list">
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>

        <div class="row text-right pb-6">
          <div class="col-sm-4 col-12">
            <!-- filter status -->
            <v-select
              v-model="statusFilter"
              :items="statusOptions"
              single-line
              outlined
              dense
              hide-details
              clearable
              :placeholder="t('Select Status')"
              :class="{ 'loyalty-list-status': $vuetify.breakpoint.smAndUp }"
            ></v-select>
          </div>

          <div class="d-flex align-center col-sm-5 col-12">
            <!-- search loyalty -->
            <v-tooltip
              :left="$vuetify.breakpoint.smAndUp"
              :top="$vuetify.breakpoint.xsOnly"
              :nudge-bottom="$vuetify.breakpoint.smAndUp ? 20 : 0"
              :nudge-top="$vuetify.breakpoint.xsOnly ? 5 : 0"
              :nudge-right="$vuetify.breakpoint.xsOnly ? 100 : 0"
              max-width="250"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-avatar
                  size="30"
                  class="mr-3 hover-pointer"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon size="25">
                    {{ icons.mdiAlertCircleOutline }}
                  </v-icon>
                </v-avatar>
              </template>
              <h3 class="my-2">
                {{ t('How to use the search bar') }}
              </h3>
              <p class="mb-1">
                {{ t('You can search using') }}:
              </p>
              <ul class="mb-2">
                <li>
                  {{ t('Record No.') }}
                </li>
                <li>
                  {{ t('Name') }}
                </li>
                <li>
                  {{ t('IC No.') }}
                </li>
                <li>
                  {{ t('Contact No.') }}
                </li>
              </ul>
            </v-tooltip>
            <v-text-field
              v-model="loyaltySearchText"
              :append-icon="icons.mdiMagnify"
              single-line
              dense
              outlined
              hide-details
              :placeholder="t('Search Loyalty')"
              :class="{ 'loyalty-list-search': $vuetify.breakpoint.mdAndUp }"
            ></v-text-field>
          </div>

          <div class="col-sm-3 col-12">
            <v-btn
              block
              color="primary"
              @click="searchLoyalties"
            >
              <v-icon
                class="pr-2"
                size="30"
              >
                {{ icons.mdiMagnify }}
              </v-icon>
              Search
            </v-btn>
          </div>
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="pt-5">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <div class="d-flex align-center">
              <v-select
                v-model="loyaltyTablePagination.per"
                :items="loyaltyListLengthOptions"
                single-line
                outlined
                dense
                hide-details
                class="profile-list-row-selection"
                @input="loyaltyListLengthChange()"
              ></v-select>
              <div class="pl-3">
                {{ t('Rows') }}
              </div>
            </div>
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="loyaltyTablePagination.current_page"
              total-visible="6"
              :length="loyaltyTablePagination.total_page"
              class="list-row-selection"
              @input="loyaltyPageChange()"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>

      <!-- table -->
      <v-data-table
        :headers="loyaltyTableColumns"
        :items="loyalties"
        :items-per-page="100"
        :loading="loading"
        mobile-breakpoint="0"
        hide-default-footer
        :disable-sort="true"
        class="text-no-wrap"
      >
        <!-- Record No. -->
        <template #[`item.id`]="{item}">
          LYT-{{ item.id }}
        </template>

        <!-- Status -->
        <template #[`item.is_expired`]="{item}">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-avatar
                size="30"
                :color="(item.is_expired && item.is_expired !== 'NULL') ? 'error' : 'success'"
                :class="`v-avatar-light-bg ${(item.is_expired && item.is_expired !== 'NULL') ? 'error' : 'success'}--text`"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon
                  size="18"
                  :color="(item.is_expired && item.is_expired !== 'NULL') ? 'error' : 'success'"
                >
                  {{ (item.is_expired && item.is_expired !== 'NULL') ? icons.mdiAlertCircleOutline : icons.mdiCheckCircleOutline }}
                </v-icon>
              </v-avatar>
            </template>
            <span>{{ (item.is_expired && item.is_expired !== 'NULL') ? 'Expired' : 'Active' }}</span>
          </v-tooltip>
        </template>
      </v-data-table>

      <v-card-text class="pt-5">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <div class="d-flex align-center">
              <v-select
                v-model="loyaltyTablePagination.per"
                :items="loyaltyListLengthOptions"
                single-line
                outlined
                dense
                hide-details
                class="profile-list-row-selection"
                @input="loyaltyListLengthChange()"
              ></v-select>
              <div class="pl-3">
                {{ t('Rows') }}
              </div>
            </div>
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="loyaltyTablePagination.current_page"
              total-visible="6"
              :length="loyaltyTablePagination.total_page"
              class="list-row-selection"
              @input="loyaltyPageChange()"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {
  mdiPlus,
  mdiCheckCircleOutline,
  mdiAlertCircleOutline,
  mdiDeleteOutline,
  mdiPencilOutline,
  mdiCalendar,
  mdiPrinter,
  mdiMagnify,
  mdiClose,
  mdiFileDocumentOutline,
  mdiHelpCircleOutline,
  mdiContentSave,
} from '@mdi/js'
import {
  onMounted,
  ref,
  inject,
  computed,
} from '@vue/composition-api'

export default {
  components: {
  },
  setup() {
    // Initializers
    const store = inject('store')
    const snackbarService = inject('snackbarService')
    const t = inject('t')
    const can = inject('can')

    // Table Handlers
    const loyaltyTableColumns = computed(() => (
      [
        { text: t('Record No.'), value: 'id', align: 'center' },
        { text: t('Status'), value: 'is_expired', align: 'center' },
        { text: t('Registration Date'), value: 'registration_date' },
        { text: t('Loyalty Type'), value: 'loyalty_type' },
        { text: t('Name'), value: 'name' },
        { text: t('IC No.'), value: 'ic_number' },
        { text: t('Contact No.'), value: 'contact_number' },
      ]
    ))

    // Properties
    const loyalties = ref([])
    const loyaltySearchText = ref('')
    const loyaltyTablePagination = ref({
      current_page: 1,
      per: 20,
      total_count: 0,
      total_page: 0,
    })
    const loading = ref(false)
    const loyaltyListLengthOptions = ref([20, 50, 100])
    const statusFilter = ref(null)
    const statusOptions = ref(['Active', 'Expired'])

    // Utils
    const formatDate = date => {
      if (!date) return null
      const [year, month, day] = date.split('-')

      return `${day}/${month}/${year}`
    }

    // Methods
    const isLoading = () => {
      loading.value = true
      snackbarService.info('Loading your list. Please hold...')
    }
    const doneLoading = () => {
      loading.value = false
      snackbarService.success('Done loading your list!')
    }
    const fetchNiagamasLoyalties = () => {
      store
        .dispatch('customerStore/fetchNiagamasLoyalties', {
          page: loyaltyTablePagination.value.current_page,
          per: loyaltyTablePagination.value.per,
          status: statusFilter.value,
          search_text: loyaltySearchText.value,
        })
        .then(response => {
          const { data, pagination } = response.data
          loyalties.value = data
          loyalties.value.forEach(field => {
            Object.keys(field).forEach(key => {
              if (field[key] === null) field[key] = 'NULL'
            })
          })
          loyaltyTablePagination.value = pagination
          doneLoading()
        })
        .catch(error => {
          loading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching niagamas loyalties. Please refresh!')
        })
    }
    const searchLoyalties = () => {
      isLoading()
      fetchNiagamasLoyalties()
    }
    const loyaltyPageChange = () => {
      isLoading()
      fetchNiagamasLoyalties()
    }
    const loyaltyListLengthChange = () => {
      loyaltyTablePagination.value.current_page = 1
      isLoading()
      fetchNiagamasLoyalties()
    }

    onMounted(() => {
      fetchNiagamasLoyalties()
    })

    return {
      t,
      can,
      loading,
      loyalties,
      loyaltyTablePagination,
      loyaltyTableColumns,
      loyaltyListLengthOptions,
      fetchNiagamasLoyalties,
      loyaltyPageChange,
      loyaltyListLengthChange,
      formatDate,
      statusFilter,
      statusOptions,
      searchLoyalties,
      loyaltySearchText,

      icons: {
        mdiPlus,
        mdiCheckCircleOutline,
        mdiAlertCircleOutline,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiCalendar,
        mdiPrinter,
        mdiMagnify,
        mdiClose,
        mdiContentSave,
        mdiFileDocumentOutline,
        mdiHelpCircleOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
  #broker-list, #corporate-list, #loyalty-list, #merchant-list {
    .profile-list-row-selection {
      max-width: 5.3rem;
    }
  }

  ::v-deep .theme--dark.v-btn.v-btn--disabled.v-btn--has-bg.loading-color {
    background: #d3d3d3 !important;
    color: #eee !important;
  }

  .dark-divider {
    border-right: 1px solid rgba(231, 227, 252, 0.14) !important;
  }

  .light-divider {
    border-right: 1px solid rgba(94, 86, 105, 0.14);
  }
</style>
